import { useCallback, useMemo, useState } from 'react';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LinkIcon from '@mui/icons-material/Link';
import EmailIcon from '@mui/icons-material/Email';
import { ResultType } from '@zarn/vendor/dist/query-logging';
import { useSelector } from 'react-redux';

import { DocAction } from '../DocActions/DocActions.interface';
import { RetrievalUnitAuthor } from 'containers/RetrievalUnit/RetrievalUnitData.interface';
import { selectDocumentsNotes } from 'containers/SavedNotes/DocumentsNotes/documentsNotes.slice';
import { getSavedNoteById } from 'containers/SavedNotes/savedNotes.utils';
import { useSendFeedback } from 'containers/Feedback/SendFeedback/hooks/useSendFeedback';
import { ShareDocumentsOptions } from 'common/components/ShareDocuments/ShareDocuments.interface';
import { ShareEnum, ShareType } from 'common/enums';
import { getSharePaperLink } from 'common/utils/share';
import { FeedbackType } from 'containers/Feedback/enums';
import { useDocNotes } from 'containers/DocNotes/hooks/useDocNotes';
import { getAuthorsFullNames } from '../retrievalUnit.utils';
import { useTenantFeatures } from 'common/hooks/useTenantFeatures';
import CampaignIcon from '@mui/icons-material/Campaign';

export type UseDocShareActionsReturn = {
  docShareActions: DocAction[];
  shareDialog: boolean | ShareEnum;
  setShareDialog: (state: boolean) => void;
  getShareLink: (
    shareType: ShareEnum,
    options?: ShareDocumentsOptions | undefined
  ) => string;
  onShare: () => void;
  notesLoading: boolean;
  onNotesInclude: () => void;
};

export interface UseDocShareActionsProps {
  id: string;
  title: string;
  searchId?: string;
  resultType?: ResultType;
  shareUri?: string;
  authorList?: RetrievalUnitAuthor[];
}

export const useDocShareActions = ({
  id,
  title,
  searchId,
  shareUri,
  resultType,
  authorList,
}: UseDocShareActionsProps): UseDocShareActionsReturn => {
  const { t } = useTranslation(['common']);
  const { palette } = useTheme();
  const [shareDialog, setShareDialog] = useState<boolean | ShareEnum>(false);
  const { sendFeedback } = useSendFeedback();
  const { data, isLoading, refetch } = useDocNotes({
    docId: id,
    options: { enabled: false },
  });
  const savedNotes = useSelector(selectDocumentsNotes);
  const savedNote = useMemo(
    () => getSavedNoteById(savedNotes, id),
    [savedNotes, id]
  );
  const hasNotes = !!savedNote?.numberOfNotes;

  const handleGetShareLink = useCallback(
    (shareType: ShareType, options?: ShareDocumentsOptions) => {
      const authors = getAuthorsFullNames({ authors: authorList });

      return getSharePaperLink[shareType]({
        id,
        title,
        authors,
        notes: options?.includeNotes ? data?.items ?? [] : [],
        shareUri,
      });
    },
    [id, shareUri, title, authorList, data?.items]
  );

  const handleNotesInclude = useCallback(() => {
    if (savedNote?.numberOfNotes) {
      void refetch();
    }
  }, [savedNote?.numberOfNotes, refetch]);

  const handleShare = useCallback(() => {
    if (searchId && resultType) {
      void sendFeedback({
        searchId,
        resultType,
        resultId: id,
        feedbackType: FeedbackType.Share,
      });
    }
  }, [searchId, resultType, sendFeedback, id]);

  const { isShareToPublicInDoc } = useTenantFeatures();

  const docShareActions = useMemo<DocAction[]>(
    () => [
      ...(isShareToPublicInDoc
        ? [
            {
              title: t('common:share.options.x'),
              Icon: CampaignIcon,
              action: () => {
                window.open(handleGetShareLink(ShareEnum.Twitter));
                handleShare();
              },
            },
          ]
        : []),
      {
        title: t('common:share.options.email'),
        Icon: EmailIcon,
        color: palette.email,
        action: () => {
          if (hasNotes) {
            setShareDialog(ShareEnum.Email);
            return;
          }

          window.open(handleGetShareLink(ShareEnum.Email));
          handleShare();
        },
      },
      {
        title: t('common:share.options.copyLink'),
        action: () => setShareDialog(ShareEnum.Link),
        Icon: LinkIcon,
      },
    ],
    [
      isShareToPublicInDoc,
      t,
      palette.email,
      handleGetShareLink,
      handleShare,
      hasNotes,
    ]
  );

  return {
    docShareActions,
    shareDialog,
    notesLoading: isLoading,
    setShareDialog,
    onShare: handleShare,
    getShareLink: handleGetShareLink,
    onNotesInclude: handleNotesInclude,
  };
};
