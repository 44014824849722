import {
  BotType,
  BotTypeMessage,
  DefaultBotTypeMessage,
  DefaultBotTypes,
  SummaryBotTypeMessage,
  TagOnboardingBotTypeMessage,
} from 'api/tenantSettingsApi/tenantSettingsApi.types';
import { Conversation } from '../Chat.types';
import { ChatMessageSenderEnum } from '@zarn/vendor/dist/search';
import { ChatContext } from 'api/chatApi/chatApi.types';
import { RetrievalUnitEnum } from 'common/enums';
import { HomePageContextValue } from 'pages/HomePage/HomePageContextProvider';

const addMessageToConversation = <T extends object = {}>(
  message: DefaultBotTypeMessage | TagOnboardingBotTypeMessage,
  conversation: Conversation<T> | null,
  botType?: BotType
): Conversation<T> => ({
  botType: conversation?.botType || botType || 'default',
  messages: message.message
    ? [
        ...(conversation?.messages || []),
        {
          sender: ChatMessageSenderEnum.User,
          content: message.message,
          ...(message.image && message.image.includes('data:image/png;base64')
            ? { image: message.image }
            : {}),
        },
      ]
    : [],
  payload: conversation?.payload,
});

const createNewSummaryConversation = <T extends object = {}>(
  message: SummaryBotTypeMessage,
  payload?: T
): Conversation<T> => ({
  botType: 'summary',
  messages: [
    {
      sender: ChatMessageSenderEnum.User,
      content: message.message,
    },
  ],
  payload,
});

const createEmptyConversation = <T extends object = {}>(
  botType: BotType,
  payload?: T
): Conversation<T> => ({
  botType,
  messages: [],
  payload,
});

export const buildChatConversation = <T extends object = {}>(
  message: BotTypeMessage,
  conversation: Conversation<T> | null
): Conversation<T> => {
  switch (message.botType) {
    case 'summary':
      return createNewSummaryConversation(message, conversation?.payload);
    case 'quizbot':
      return createEmptyConversation(message.botType, conversation?.payload);
    default:
    case 'chat_with_tag':
    case 'chat_with_pdf':
    case 'chat_with_qa_widget':
      return addMessageToConversation(message, conversation, message.botType);
  }
};

export const getContext = (
  docsIds: string[] | undefined
): ChatContext | undefined => {
  return docsIds
    ? {
        fromDocIds: {
          docId: docsIds,
          retrievalUnit: RetrievalUnitEnum.Document,
          contextFields: [],
        },
      }
    : undefined;
};

export const getContextFromQA = (
  noteId: string | null,
  docsIds: string[]
): ChatContext => {
  return docsIds
    ? {
        fromDocIds: {
          docId: docsIds,
          retrievalUnit: RetrievalUnitEnum.Document,
          contextFields: [],
        },
      }
    : {};
};

export const getPredefinedConversation = (
  q: string | null,
  noteId: string | null,
  docsIds: string[],
  homeContext: HomePageContextValue | null,
  botType: DefaultBotTypes
): Conversation<{ docIds?: string[] }> | null => {
  if (homeContext?.chatResponse) {
    var chatResponse = homeContext.chatResponse;
    return q && !noteId && chatResponse.conversation
      ? {
          botType: botType,
          messages: chatResponse.conversation,
          payload: {
            docIds: docsIds,
          },
        }
      : null;
  } else {
    return q &&
      !noteId &&
      homeContext?.bestAnswerQuestion &&
      homeContext?.bestAnswerAnswer
      ? {
          botType: botType,
          messages: [
            {
              sender: 'user',
              content: `${homeContext.bestAnswerQuestion}`,
            },
            {
              sender: 'bot',
              content: `${homeContext.bestAnswerAnswer}`,
            },
          ],
          payload: {
            docIds: docsIds,
          },
        }
      : null;
  }
};
