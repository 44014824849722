import React, { FC } from 'react';
import { useDocumentLogo } from './hooks/useDocumentLogo';
import { DocAssetTypeEnum } from 'common/enums/DocAssetType.enum';
import { useSelector } from 'react-redux';
import { selectIndexCluster } from '../../User/user.slice';
import { DocLogo } from '../RetrievalUnitData.interface';
import { AdaptiveImage } from './components/AdaptiveImage';
import { DocCardComposition } from '../../User/User.enum';
import { Avatar, Theme, Typography, useTheme } from '@mui/material';

interface Props {
  guid: string;
  logo: DocLogo;
  fallback: string;
  cardComposition?: DocCardComposition;
  initials: string;
}

export const DocMediaLogo: FC<Props> = ({
  guid,
  fallback,
  cardComposition,
  initials,
}) => {
  const indexCluster = useSelector(selectIndexCluster);
  const { data, isLoading, error } = useDocumentLogo({
    docGuid: guid,
    asset: {
      assetType: DocAssetTypeEnum.Logo,
      assetValue: '',
    },
    indexCluster: indexCluster,
  });

  const { palette } = useTheme<Theme>();

  return (
    <AdaptiveImage
      src={data?.url ?? fallback}
      fallback={fallback}
      isLoading={isLoading}
      error={error}
      cardComposition={cardComposition}
    >
      {initials && (
        <Avatar
          sx={{
            width: 24,
            height: 24,
            marginLeft: 'auto',
            marginRight: 0,
            top: '-1rem',
            backgroundColor: palette.secondary.dark,
          }}
        >
          <Typography variant="body2">{initials}</Typography>
        </Avatar>
      )}
    </AdaptiveImage>
  );
};
