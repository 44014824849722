import { useQuery, UseQueryOptions } from 'react-query';

import { getDocumentAsset } from 'api/documentAssetApi/documentAssetApi';
import { BaseError } from 'common/models/Error.interface';
import { DocLogo } from '../../RetrievalUnitData.interface';
import { useParsedHostname } from 'common/utils/useParsedHostname';
import { QUERY_OPTIONS } from 'common/constants/query-options';

export const docLogoQueryKeys = {
  all: ['docLogo'] as const,
  details: (
    docGuid: string,
    assetType: string,
    assetValue: string,
    indexCluster?: string
  ) =>
    [
      ...docLogoQueryKeys.all,
      docGuid,
      assetType,
      assetValue,
      indexCluster,
    ] as const,
};

export interface DocumentLogoData {
  url: string;
}

export type UseDocumentLogoProps = {
  docGuid: string;
  asset: DocLogo;
  options?: Omit<
    UseQueryOptions<DocumentLogoData, BaseError>,
    'queryKey' | 'queryFn'
  >;
  indexCluster?: string | null;
};

export const useDocumentLogo = ({
  docGuid,
  asset: { assetType, assetValue },
  options,
  indexCluster,
}: UseDocumentLogoProps) => {
  const { tenant } = useParsedHostname();
  const query = useQuery<DocumentLogoData, BaseError>(
    docLogoQueryKeys.details(
      docGuid,
      assetType,
      assetValue,
      indexCluster as string | undefined
    ),
    async () => {
      const { data } = await getDocumentAsset({
        docGuid,
        assetType: 'logo_url',
        indexCluster: indexCluster ?? undefined,
        tenant,
      });

      return {
        url: data.downloadUrl,
      };
    },
    {
      ...QUERY_OPTIONS,
      retry: 1,
      ...options,
      enabled: options?.enabled,
    }
  );

  return query;
};
