import React from 'react';
import { useHistory } from 'react-router-dom';

import { ResultType } from '@zarn/vendor/dist/query-logging';
import { useSendFeedback } from 'containers/Feedback/SendFeedback/hooks/useSendFeedback';
import { FeedbackType } from 'containers/Feedback/enums';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import { posthogPDFReader } from 'common/utils/posthog.utils';
import { useLoggedInFeature } from 'containers/Auth/hooks/useLoggedInFeature';
import { docGuidToDocId, docIdToDocGuid } from 'common/utils/documents';
import DocTitleLink from 'common/components/Docs/DocTitleLink';
import withTrackedAction, {
  WithTrackedActionProps,
  WithTrackedActionWrappedProps,
} from 'common/components/TrackedActions/withTrackedAction';
import { useParsedHostname } from 'common/utils/useParsedHostname';
import { useNavigationWithState } from 'common/hooks/useNavigationWithState';

export type DocTitleWithPDFProps = {
  title: string;
  id: string;
  searchId?: string;
  resultType?: ResultType;
  docGuid: string;
  organizeDocId: string;
  onClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
};

const DocTitleWithPDF = React.memo(
  ({
    onClick,
    onAction,
    resultType,
    searchId,
    id,
    title,
    docGuid,
    organizeDocId,
  }: WithTrackedActionWrappedProps<DocTitleWithPDFProps>) => {
    const { tenant } = useParsedHostname();
    const { sendFeedback } = useSendFeedback();
    const history = useHistory();
    const docId = docGuidToDocId(docGuid);

    const { locationState } = useNavigationWithState();

    // Remove chunk index and add _0 to display the full PDF
    const docHash = docIdToDocGuid(organizeDocId);
    const pdfDocId = docGuidToDocId(docHash);
    const action = useLoggedInFeature<boolean>(() => {
      history.push(`/pdf/${pdfDocId}`, {
        ...locationState,
        background: history.location,
      });
    });

    const handleClick = (
      event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => {
      action(true);
      posthogPDFReader.open(tenant, docId);
      if (searchId && resultType) {
        void sendFeedback({
          searchId,
          resultType,
          resultId: id,
          feedbackType: FeedbackType.Click,
        });
      }
      onAction();
      if (!onClick) return;

      onClick(event);
    };

    return (
      <DocTitleLink
        docTitle={title}
        docId={id}
        linkType="pdf"
        role="button"
        data-testid="retrievalUnitTitleWithPDFLink"
        onClick={handleClick}
      />
    );
  }
);

DocTitleWithPDF.displayName = 'DocTitleWithPDF';

export default withTrackedAction<DocTitleWithPDFProps & WithTrackedActionProps>(
  DocTitleWithPDF,
  TrackEventName.DocumentTitleWithPDFClicked
);
