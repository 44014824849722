import { format, startOfYesterday, sub } from 'date-fns';

import {
  FederatedSearchValueEnum,
  RetrievalMethodEnum,
  RetrievalMethodEnumType,
  RetrievalUnitEnum,
  SearchEngineEnum,
  SearchSortMethodEnum,
  SearchTermEnum,
  SearchTermEnumType,
  SearchType,
  SearchTypeEnum,
  SearchUrlParamsEnum,
} from 'common/enums';
import { SearchVisibilityFilterValue } from 'containers/Search/SearchFilters/SearchFilters.interface';
import {
  DATE_FILTER_WITH_CITATIONS_FOR_TERM,
  DEFAULT_RETRIEVAL_METHOD,
  DEFAULT_RETRIEVAL_UNIT,
  DEFAULT_SEARCH_SORT_METHODS,
} from 'containers/Search/constants/defaultSearchValues';
import {
  SearchPayloadDocType,
  SearchPayloadTerm,
} from 'containers/Search/SearchPayload.interface';
import { SearchDateFilterValueEnum } from 'containers/Search/SearchFilters/SearchDateFilter/SearchDateFilterValue.enum';
import { DynamicSettings } from 'api/tenantSettingsApi/tenantSettingsApi.types';
import { isDynamicFilter } from './dynamicFilters.utils';

export const createSearchQueryString = (params: {
  [SearchUrlParamsEnum.Query]?: string;
  [SearchUrlParamsEnum.Page]?: string;
  [SearchUrlParamsEnum.Term]?: string;
  [SearchUrlParamsEnum.RetrievalMethod]?: string;
  [SearchUrlParamsEnum.RetrievalUnit]?: string;
  [SearchUrlParamsEnum.DocSources]?: string;
  [SearchUrlParamsEnum.DocIds]?: string;
  [SearchUrlParamsEnum.SortBy]?: string;
  [SearchUrlParamsEnum.SimilarTo]?: string;
  [SearchUrlParamsEnum.Date]?: string;
  [SearchUrlParamsEnum.DateRange]?: string;
  [SearchUrlParamsEnum.WithCode]?: string;
  [SearchUrlParamsEnum.DocTypes]?: string;
  [SearchUrlParamsEnum.FederatedSearch]?: string;
  [SearchUrlParamsEnum.IndexCluster]?: string;
  [SearchUrlParamsEnum.QueryEncoderService]?: string;
  [SearchUrlParamsEnum.RerankerService]?: string;
  [SearchUrlParamsEnum.Tenant]?: string;
  [SearchUrlParamsEnum.Rerank]?: string;
  [SearchUrlParamsEnum.Reranker]?: string;
  [SearchUrlParamsEnum.RerankTopN]?: string;
  [SearchUrlParamsEnum.Visibility]?: string;
  [SearchUrlParamsEnum.Organizations]?: string;
  [SearchUrlParamsEnum.Countries]?: string;
  [SearchUrlParamsEnum.Tag]?: string;
}): string => {
  return Object.entries(params).reduce((acc, cur) => {
    return `${acc}${cur[0]}=${cur[1]}&`;
  }, '?');
};

export const serializeDateValue = (date: Date): string =>
  format(date, "yyyy-MM-dd'T'HH:mm:ss");

export function serializeRetrievalMethodValue(
  retrievalMethod: RetrievalMethodEnum
): string {
  return retrievalMethod
    ? RetrievalMethodEnum.Knn
    : RetrievalMethodEnum.Keyword;
}

export function serializeYearsRangeValue([min, max]: [number, number]): string {
  return `${min},${max}`;
}

export function deserializeOnlyWithCodeFilterValue(
  searchParams: URLSearchParams,
  dynamicFiltersSettings?: DynamicSettings[] | null
): boolean {
  if (isDynamicFilter(SearchUrlParamsEnum.WithCode, dynamicFiltersSettings)) {
    return false;
  }
  return JSON.parse(searchParams.get(SearchUrlParamsEnum.WithCode) ?? 'false');
}

export function deserializeVisiblityFilterValue(
  searchParams: URLSearchParams
): SearchVisibilityFilterValue {
  switch (searchParams.get(SearchUrlParamsEnum.Visibility)) {
    case SearchVisibilityFilterValue.Private:
      return SearchVisibilityFilterValue.Private;
    case SearchVisibilityFilterValue.Public:
      return SearchVisibilityFilterValue.Public;
    default:
      return SearchVisibilityFilterValue.All;
  }
}

export function getSearchType(searchParams: URLSearchParams): SearchType {
  const query = searchParams.get(SearchUrlParamsEnum.Query);
  const term = searchParams.get(SearchUrlParamsEnum.Term);

  if (term) return SearchTypeEnum.Term;
  if (query) return SearchTypeEnum.Query;

  return SearchTypeEnum.Default;
}

export function newSearchParamsOnQueryChange(
  queryValue: string,
  search: string
): string {
  const searchParams = new URLSearchParams(search);
  searchParams.set(SearchUrlParamsEnum.Query, queryValue);
  searchParams.delete(SearchUrlParamsEnum.Page);
  searchParams.delete(SearchUrlParamsEnum.Term);
  searchParams.delete(SearchUrlParamsEnum.SimilarTo);

  return `?${searchParams.toString()}`;
}

export function deserializeDocSources(
  searchParams: URLSearchParams,
  dynamicFiltersSettings?: DynamicSettings[] | null
): string[] {
  const sources = searchParams.get(SearchUrlParamsEnum.DocSources);
  if (
    isDynamicFilter(SearchUrlParamsEnum.DocSources, dynamicFiltersSettings) ||
    !sources
  ) {
    return [];
  }

  return sources.split(',');
}

export function deserializeDocIds(
  searchParams: URLSearchParams,
  dynamicFiltersSettings?: DynamicSettings[] | null
): string[] | undefined {
  const ids = searchParams.get(SearchUrlParamsEnum.DocIds);

  if (
    isDynamicFilter(SearchUrlParamsEnum.DocIds, dynamicFiltersSettings) ||
    !ids
  ) {
    return undefined;
  }

  return ids.split(',');
}

export function deserializeSearchQueryArrayParam<T extends string>(
  value: string | null
): T[] | undefined {
  if (!value || value === '') return undefined;

  return value.split(',').filter((v) => v) as T[];
}

export function deserializeDateRange(
  searchParams: URLSearchParams
): [string, string] | null {
  const dateRange = searchParams.get(SearchUrlParamsEnum.DateRange);
  if (dateRange) {
    const value: string[] = dateRange.split(',');
    return [value[0], value[1]];
  }

  return null;
}

export function getDateRangeFromDateFilterValue(
  value: string
): [string, string] | undefined {
  const now = new Date();

  switch (value) {
    case SearchDateFilterValueEnum.LastDay:
      return [serializeDateValue(startOfYesterday()), serializeDateValue(now)];
    case SearchDateFilterValueEnum.LastWeek:
      return [
        serializeDateValue(sub(now, { days: 7 })),
        serializeDateValue(now),
      ];
    case SearchDateFilterValueEnum.LastMonth:
      return [
        serializeDateValue(sub(now, { months: 1 })),
        serializeDateValue(now),
      ];
    case SearchDateFilterValueEnum.Last3Months:
      return [
        serializeDateValue(sub(now, { months: 3 })),
        serializeDateValue(now),
      ];
    case SearchDateFilterValueEnum.LastYear:
      return [
        serializeDateValue(sub(now, { years: 1 })),
        serializeDateValue(now),
      ];
    default:
      return undefined;
  }
}

export function deserializeRetrievalUnit(
  searchParams: URLSearchParams,
  dynamicFiltersSettings?: DynamicSettings[] | null
): RetrievalUnitEnum {
  const retrievalUnit = searchParams.get(
    SearchUrlParamsEnum.RetrievalUnit
  ) as RetrievalUnitEnum;

  if (
    !retrievalUnit ||
    isDynamicFilter(SearchUrlParamsEnum.RetrievalUnit, dynamicFiltersSettings)
  ) {
    return DEFAULT_RETRIEVAL_UNIT;
  }

  return retrievalUnit;
}

export function deserializeRelativeDate(
  searchParams: URLSearchParams,
  dynamicFiltersSettings?: DynamicSettings[] | null
): SearchDateFilterValueEnum | undefined {
  if (isDynamicFilter(SearchUrlParamsEnum.Date, dynamicFiltersSettings)) {
    return undefined;
  }

  const val = searchParams.get(
    SearchUrlParamsEnum.Date
  ) as SearchDateFilterValueEnum;

  for (const value of Object.values(SearchDateFilterValueEnum)) {
    if (val === value) return value;
  }
}

export function deserializeFederatedSearchValue(
  searchParams: URLSearchParams,
  dynamicFiltersSettings?: DynamicSettings[] | null
): SearchEngineEnum | undefined {
  if (
    isDynamicFilter(SearchUrlParamsEnum.FederatedSearch, dynamicFiltersSettings)
  ) {
    return undefined;
  }

  const val = searchParams.get(
    SearchUrlParamsEnum.FederatedSearch
  ) as FederatedSearchValueEnum;

  switch (val) {
    case FederatedSearchValueEnum.GoogleScholar:
      return SearchEngineEnum.GoogleScholar;
    case FederatedSearchValueEnum.Bing:
      return SearchEngineEnum.Bing;
    case FederatedSearchValueEnum.Google:
      return SearchEngineEnum.Google;
    case FederatedSearchValueEnum.Default:
      return SearchEngineEnum.ZetaAlpha;
    default:
      return val ? (val as SearchEngineEnum) : undefined; // Workaround to still accept any value
  }
}

export const deserializeIndexCluster = (searchParams: URLSearchParams) =>
  searchParams.get(SearchUrlParamsEnum.IndexCluster) || null;

export function deserializeQueryEncoderService(
  searchParams: URLSearchParams,
  dynamicFiltersSettings?: DynamicSettings[] | null
): string | undefined {
  if (
    isDynamicFilter(
      SearchUrlParamsEnum.QueryEncoderService,
      dynamicFiltersSettings
    )
  ) {
    return undefined;
  }
  return searchParams.get(SearchUrlParamsEnum.QueryEncoderService) || undefined;
}

export function deserializeRerankerService(
  searchParams: URLSearchParams,
  dynamicFiltersSettings?: DynamicSettings[] | null
): string | undefined {
  if (
    isDynamicFilter(SearchUrlParamsEnum.RerankerService, dynamicFiltersSettings)
  ) {
    return undefined;
  }
  return searchParams.get(SearchUrlParamsEnum.RerankerService) || undefined;
}

export function deserializeTenant(
  searchParams: URLSearchParams
): string | undefined {
  return searchParams.get(SearchUrlParamsEnum.Tenant) || undefined;
}

export function deserializeRerank(
  searchParams: URLSearchParams,
  dynamicFiltersSettings?: DynamicSettings[] | null
): boolean | undefined {
  if (isDynamicFilter(SearchUrlParamsEnum.Rerank, dynamicFiltersSettings)) {
    return undefined;
  }
  const value = searchParams.get(SearchUrlParamsEnum.Rerank);
  return value === 'true' ? true : undefined;
}

export function deserializeReranker(
  searchParams: URLSearchParams,
  dynamicFiltersSettings?: DynamicSettings[] | null
): string | undefined {
  if (isDynamicFilter(SearchUrlParamsEnum.Reranker, dynamicFiltersSettings)) {
    return undefined;
  }
  return searchParams.get(SearchUrlParamsEnum.Reranker) ?? undefined;
}

export function deserializeRerankTopN(
  searchParams: URLSearchParams,
  dynamicFiltersSettings?: DynamicSettings[] | null
): number | undefined {
  if (isDynamicFilter(SearchUrlParamsEnum.RerankTopN, dynamicFiltersSettings)) {
    return undefined;
  }
  const value = searchParams.get(SearchUrlParamsEnum.RerankTopN);
  return (value && parseInt(value)) || undefined;
}

export function deserializeRetrievalMethod(
  searchParams: URLSearchParams,
  dynamicFiltersSettings?: DynamicSettings[] | null
): RetrievalMethodEnumType {
  const retrievalMethod = searchParams.get(
    SearchUrlParamsEnum.RetrievalMethod
  ) as RetrievalMethodEnumType;

  if (
    !retrievalMethod ||
    isDynamicFilter(SearchUrlParamsEnum.RetrievalMethod, dynamicFiltersSettings)
  ) {
    return DEFAULT_RETRIEVAL_METHOD;
  }

  return retrievalMethod;
}

export function deserializeSortBy(
  searchParams: URLSearchParams
): SearchSortMethodEnum {
  const searchType = getSearchType(searchParams);
  const sortMethod = (
    searchParams.get(SearchUrlParamsEnum.SortBy) ?? ''
  ).toLowerCase() as SearchSortMethodEnum;

  if (Object.values(SearchSortMethodEnum).includes(sortMethod)) {
    return sortMethod;
  }

  return DEFAULT_SEARCH_SORT_METHODS[searchType];
}

export function deserializeTermFilter(
  searchParams: URLSearchParams
): SearchPayloadTerm | undefined {
  const [termName, value] = (
    searchParams.get(SearchUrlParamsEnum.Term) || ''
  ).split(',');

  if (!termName || !value) return undefined;

  switch (termName.toLowerCase()) {
    case SearchTermEnum.AuthoredBy:
    case SearchTermEnum.CitedBy:
    case SearchTermEnum.Cites:
    case SearchTermEnum.HasConcept:
      return [termName as SearchTermEnumType, value];
    default:
      return undefined;
  }
}

export const getConceptTerm = (search: string): string | null => {
  const searchParams = new URLSearchParams(search);
  const filterTerm = deserializeTermFilter(searchParams);

  if (filterTerm && filterTerm[0] === SearchTermEnum.HasConcept) {
    return filterTerm[1];
  }

  return null;
};

export function deserializeDateFilter(
  value: string,
  dynamicFiltersSettings?: DynamicSettings[] | null
) {
  if (isDynamicFilter(SearchUrlParamsEnum.Date, dynamicFiltersSettings)) {
    return '';
  }
  return value;
}

export const getAuthorTerm = (search: string): string | null => {
  const searchParams = new URLSearchParams(search);
  const filterTerm = deserializeTermFilter(searchParams);

  if (filterTerm && filterTerm[0] === SearchTermEnum.AuthoredBy) {
    return filterTerm[1];
  }

  return null;
};

export const getCitationTerm = (
  searchParams: URLSearchParams
): string | null => {
  const filterTerm = deserializeTermFilter(searchParams);

  if (
    filterTerm &&
    (filterTerm[0] === SearchTermEnum.Cites ||
      filterTerm[0] === SearchTermEnum.CitedBy)
  ) {
    return filterTerm[1];
  }

  return null;
};

export const hasConceptTerm = (
  term: SearchPayloadTerm | undefined
): boolean => {
  return !!(term && term[0] === SearchTermEnum.HasConcept);
};

export const getQueryFromURL = (search: string): string => {
  return new URLSearchParams(search).get(SearchUrlParamsEnum.Query) ?? '';
};

export function getDocTypes(
  searchParams: URLSearchParams,
  dynamicFiltersSettings?: DynamicSettings[] | null
): SearchPayloadDocType[] {
  if (isDynamicFilter(SearchUrlParamsEnum.DocTypes, dynamicFiltersSettings)) {
    return [];
  }

  const docTypesValue = searchParams.get(SearchUrlParamsEnum.DocTypes);
  const dateFilter = searchParams.get(SearchUrlParamsEnum.Date);
  const term = deserializeTermFilter(searchParams);

  if (
    term &&
    (!dateFilter ||
      DATE_FILTER_WITH_CITATIONS_FOR_TERM.includes(
        dateFilter as SearchDateFilterValueEnum
      ))
  ) {
    return ['document', 'citation'];
  }

  return (
    deserializeSearchQueryArrayParam<SearchPayloadDocType>(docTypesValue) ?? []
  );
}

export function serializeWithContent(searchParams: URLSearchParams): boolean {
  const dateFilter = searchParams.get(SearchUrlParamsEnum.Date);
  const term = deserializeTermFilter(searchParams);

  return !(
    term &&
    (!dateFilter ||
      DATE_FILTER_WITH_CITATIONS_FOR_TERM.includes(
        dateFilter as SearchDateFilterValueEnum
      ))
  );
}
