import React, { FC, MouseEventHandler, useState } from 'react';
import { Box } from '@mui/material';
import { NoteItemContext } from '../noteItem.context';
import { useUserDetails } from 'containers/Users/hooks/useUserDetails';
import {
  AnnotationHighlight,
  NoteDetails,
  NoteObjectId,
} from 'api/notesApi/notesApi.types';
import NoteAnnotationHighlightItem from '../NoteAnnotationHighlightItem';
import NoteItemMetadata from '../NoteItemMetadata';
import { NoteItemContent } from '../NoteItemContent';
import { Actions } from './components/Actions';
import { Content } from './components/Content';
import { getInitialsFromFullName } from 'common/utils/author';
import { useNoteConversation } from 'containers/Chat/hooks/useConversation/useNoteConversation';
import { useTagChatOpen } from 'containers/Tags/hooks/useTagChatOpen';
import { useOpenPDFChat } from './hooks/useOpenPDFChat';
import { useChatAvailability } from 'common/hooks/useChatAvailability';
import { MoreLessButton } from '../../MoreLessButton/MoreLessButton';
import { useStyles } from './useStyles';
import { isFeatureEnabled } from 'common/components/FeatureFlags/Feature';
import { useOpenQAChat } from 'pages/HomePage/SideDrawer/hooks/useOpenQAChat';

export interface NoteItemProps {
  note: NoteDetails;
  docId?: string | undefined;
  onDelete: (objectId: NoteObjectId, noteId: number) => Promise<void>;
  onUpdate: (note: NoteDetails) => Promise<void>;
  onHighlightClick?: (highlight: AnnotationHighlight) => void;
  onOpenChatClick?: () => void;
  tagId?: number | null;
}

export const NoteItem: FC<NoteItemProps> = ({
  note,
  docId,
  onDelete,
  onUpdate,
  onHighlightClick,
  onOpenChatClick,
  tagId,
}) => {
  const classes = useStyles();

  const { isChatConversation, conversation } = useNoteConversation(note);

  const { dateModified, permission, annotationHighlight } = note;

  const { data: noteOwner } = useUserDetails({
    memberId: note.ownerId ?? '',
    options: { enabled: !!note.ownerId },
  });

  const isHighlightImage =
    annotationHighlight && annotationHighlight.content.type === 'image';

  const isExplainVisible = isFeatureEnabled('ff-multimodal-chat')
    ? !!docId
    : !!(docId && !isHighlightImage);

  const [editMode, setEditMode] = useState(false);

  const { isChatAvailable } = useChatAvailability();
  const isChatEnabled = isChatAvailable;
  const { openTagChat } = useTagChatOpen();
  const { openPDFChat } = useOpenPDFChat({
    onOpenChatClick,
    docId,
    noteId: note.id,
    ...(note.annotationHighlight?.content.body.includes('data:image/png;base64')
      ? { noteImage: note.annotationHighlight?.content.body }
      : {}),
  });
  const { openQAChat } = useOpenQAChat({ onOpenChatClick, noteId: note.id });

  const [isShortConversation, setIsShortConversation] = useState(true);

  const handleOpenChat = () => {
    if (tagId) {
      openTagChat(tagId, note.id);
    }
    if (docId) {
      openPDFChat();
    }
    if (!tagId && !docId) {
      openQAChat();
    }
  };

  const handleChatViewToggle: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    setIsShortConversation((v) => !v);
  };

  const handleChatClick = () => {
    if (isShortConversation) {
      setIsShortConversation(false);
      return;
    }
    handleOpenChat();
  };

  const editModeToggle = () => {
    if (isChatConversation) {
      return handleChatClick();
    }
    if (editable) {
      setEditMode(!editMode);
    }
  };

  const handleHighlightClick = () => {
    if (!onHighlightClick || !annotationHighlight) return;

    onHighlightClick(annotationHighlight);
  };

  const handleToggleShareable = async () => {
    await onUpdate({
      ...note,
      shareable: !note.shareable,
    });
  };

  const editable =
    permission === 'modify' || (isChatConversation && isShortConversation);

  return (
    <NoteItemContext.Provider value={{ editMode, editModeToggle, note }}>
      <Box
        aria-label="note"
        display="flex"
        flexDirection="column"
        my={1}
        data-testid="NoteItem"
        className={classes.root}
      >
        <Box
          display="flex"
          alignItems="baseline"
          justifyContent="space-between"
        >
          <NoteItemMetadata
            dateModified={dateModified}
            ownerFullName={noteOwner?.fullName}
          />
          <Actions
            note={note}
            docId={docId}
            isExplainVisible={isExplainVisible}
            isChatConversation={isChatConversation}
            isChatEnabled={isChatEnabled}
            editable={editable}
            onDelete={onDelete}
            onToggleShareable={handleToggleShareable}
            onOpenChatNote={handleOpenChat}
            onEditModeToggle={editModeToggle}
          />
        </Box>

        {annotationHighlight && (
          <Box mb={1}>
            <NoteAnnotationHighlightItem
              color={annotationHighlight.color}
              contentType={annotationHighlight.content.type}
              contentBody={annotationHighlight.content.body}
              onClick={onHighlightClick ? handleHighlightClick : undefined}
            />
          </Box>
        )}

        <Content
          visible={!!note.content}
          editMode={editMode}
          editable={editable}
          validate={!!annotationHighlight}
          onUpdate={onUpdate}
          onModeToggle={editModeToggle}
        >
          <NoteItemContent
            editable={editable}
            highlight={note.highlight}
            content={note.content}
            initials={getInitialsFromFullName(noteOwner?.fullName)}
            isChatConversation={isChatConversation}
            isShortConversation={isShortConversation}
            conversation={conversation}
          />
          {conversation && (
            <MoreLessButton
              isLess={!isShortConversation}
              onClick={handleChatViewToggle}
              wrapperClassName={classes.moreLess}
            />
          )}
        </Content>
      </Box>
    </NoteItemContext.Provider>
  );
};
