import React, { useState } from 'react';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardProps,
  Chip,
  ClickAwayListener,
  Collapse,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { DocumentItem } from '@zarn/vendor/dist/saved-results';
import { HitType, SearchEngineEnum } from 'common/enums';
import { ResultType } from '@zarn/vendor/dist/query-logging';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';
import { AuthorsList } from 'common/components/AuthorsList/AuthorsList';
import SendFeedback from 'containers/Feedback/SendFeedback/SendFeedback';
import DocNotesResults from 'containers/DocNotes/DocNotesResults';
import DocNoteCreate from 'containers/DocNotes/DocNoteCreate';
import DocTags from 'containers/SavedDocuments/DocTags';
import DocTitle from 'common/components/Docs/DocTitle/DocTitle';
import DocCites from 'common/components/Docs/DocCites';
import DocRefs from 'common/components/Docs/DocRefs';
import DocMetadata from 'common/components/Docs/DocMetadata/DocMetadata';
import DocDate from 'common/components/Docs/DocDate/DocDate';
import { FeedbackData } from 'containers/Feedback/Feedback.interface';
import { TagDetailsBase } from 'api/tagsApi/tagsApi.types';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import { useStyles } from '../styles';
import { DocAction } from '../DocActions/DocActions.interface';
import { DocImage } from '../DocImage/DocImage';
import { RetrievalUnitExplanation } from '../RetrievalUnitExplanation/RetrievalUnitExplanation';
import DocStatus from '../DocStatus/DocStatus';
import DocSource from '../DocSource';
import DocTitleWithPDF from '../DocTitleWithPDF';
import DocTitleWithExternalPDF from '../DocTitleWithExternalPDF';
import DocAbstract from '../DocAbstract';
import { RetrievalUnitTweets } from '../RetrievalUnitTweets/RetrievalUnitTweets';
import { RetrievalUnitGithub } from '../RetrievalUnitGithub/RetrievalUnitGithub';
import { DocInlineTags } from '../DocInlineTags';
import DocOwner from '../DocOwner/DocOwner';
import DocSimilarToButton from '../DocSimilarToButton';
import DocNotesToggle from '../DocNotesToggle';
import DocFavoriteButton from '../DocFavoriteButton';
import DocShare from '../DocShare';
import DocActions from '../DocActions';
import { useAuth } from 'containers/Auth/hooks/useAuth';
import DocOwnerFromOrg from '../DocOwnerFromOrg/DocOwnerFromOrg';
import { DocSourcesEnum } from 'common/utils/documents';

const DOCUMENT_TYPES: string[] = [
  HitType.Document,
  HitType.PrivateDocument,
  HitType.ExternalDocument,
];

export type RetrievalUnitCardDefaultProps = {
  data: RetrievalUnitData;
  withImage?: boolean;
  highlighted?: boolean;
  moreActions?: DocAction[];
  cardProps?: CardProps;
  explanation?: string;
  searchId?: string;
  resultType?: ResultType;
  withExplicitFeedback?: boolean;
  withSharedNotes?: boolean;
  withTagging?: boolean;
  suggestedTags?: TagDetailsBase[];
  searchEngine?: SearchEngineEnum;
  onDocumentAdd?: (addedDocuments: DocumentItem[]) => any;
  onPrivateDocDelete?: (id: string) => void;
  onPrivateDocEdit?: (data: RetrievalUnitData) => void;
  feedback?: FeedbackData;
  classes?: {
    root?: string;
  };
  evidenceNumber?: number;
  mapDocIndex?: number;
  pageNumber?: number;
};

export default function RetrievalUnitCardDefault({
  data,
  moreActions,
  cardProps,
  explanation,
  searchId,
  resultType,
  withExplicitFeedback,
  withSharedNotes,
  highlighted,
  suggestedTags,
  onPrivateDocDelete,
  onPrivateDocEdit,
  feedback,
  searchEngine,
  withImage = true,
  withTagging = true,
  classes: inputClasses,
  evidenceNumber,
  mapDocIndex,
  pageNumber,
}: RetrievalUnitCardDefaultProps) {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const [notesExpanded, setNotesExpanded] = useState<boolean>(false);
  const { me } = useAuth();

  const {
    guid,
    ontologyId,
    getSimilarDocsId,
    numberOfCitations,
    numberOfRefs,
    document,
    source,
    title,
    abstractContent,
    representations,
    authors,
    shareUri,
    organizeDocId,
    getCitesId = null,
    getRefsId = null,
    document: { id: docId },
    privateAsset,
    publicAsset,
    externalPublicAsset,
    status,
  } = data;

  const docAbstract =
    source === DocSourcesEnum.GitHub ? representations.text : abstractContent;
  const isPrivateDoc = document.type === HitType.PrivateDocument;
  const isDocumentType = DOCUMENT_TYPES.includes(document.type);
  const renderFeedback = !!(withExplicitFeedback && searchId && resultType);
  const renderCites = typeof numberOfCitations === 'number';
  const renderRefs = typeof numberOfRefs === 'number';
  const hasPDF = privateAsset || publicAsset || externalPublicAsset;

  const handleNotesAccordionClickAway = () => {
    if (notesExpanded) {
      setNotesExpanded(false);
    }
  };

  return (
    <Card
      {...cardProps}
      aria-label="retrieval unit"
      elevation={notesExpanded ? 10 : 1}
      className={clsx(
        classes.card,
        highlighted && classes.highlighted,
        inputClasses?.root
      )}
      data-testid="RetrievalUnitCardDefault"
    >
      <Grid container spacing={0} p={1}>
        {withImage && <DocImage data={data} />}
        <Grid item xs p={0}>
          <CardContent className={classes.cardContent}>
            {explanation && (
              <RetrievalUnitExplanation explanation={explanation} />
            )}
            {status && <DocStatus status={status} />}
            <div>
              {evidenceNumber && (
                <Typography component="span" pr={1}>
                  {evidenceNumber}.
                </Typography>
              )}
              {mapDocIndex && pageNumber === 1 && (
                <Typography component="span" pr={1}>
                  {mapDocIndex}.
                </Typography>
              )}
              <DocSource
                uri={data.uri}
                id={docId}
                source={data.source}
                searchId={searchId}
                resultType={resultType}
                duplicates={data.duplicates}
              />

              <>
                {!hasPDF && (
                  <DocTitle
                    uri={data.uri}
                    title={title}
                    id={docId}
                    searchId={searchId}
                    resultType={resultType}
                  />
                )}
                {(publicAsset || privateAsset) && (
                  <DocTitleWithPDF
                    title={title}
                    id={docId}
                    searchId={searchId}
                    resultType={resultType}
                    docGuid={guid}
                    organizeDocId={organizeDocId}
                  />
                )}
                {externalPublicAsset && (
                  <DocTitleWithExternalPDF
                    title={title}
                    id={docId}
                    searchId={searchId}
                    resultType={resultType}
                    docData={data}
                    searchEngine={searchEngine}
                  />
                )}
              </>
            </div>

            <DocMetadata>
              <DocDate date={data.date} year={data.year} />
              {authors && <AuthorsList authors={authors} />}
            </DocMetadata>

            {!isDocumentType && document.type && (
              <Chip size="small" label={document.type} />
            )}

            <DocAbstract
              highlight={data.highlight}
              abstractContent={docAbstract}
              id={docId}
              searchId={searchId}
              resultType={resultType}
            />
            <div className={classes.additionalMetadata}>
              {renderCites && numberOfCitations > 0 && (
                <DocCites score={numberOfCitations} getCitesId={getCitesId} />
              )}

              {renderRefs && numberOfRefs > 0 && (
                <DocRefs score={numberOfRefs} getRefsId={getRefsId} />
              )}

              <RetrievalUnitTweets
                tweets={data.tweets}
                numberOfTweets={data.numberOfTweets}
                resultType={resultType}
                searchId={searchId}
                documentId={docId}
              />

              {typeof data.githubScore === 'number' && (
                <RetrievalUnitGithub
                  score={data.githubScore}
                  repos={data.githubRepos}
                  resultType={resultType}
                  searchId={searchId}
                  documentId={docId}
                />
              )}
            </div>
            {withTagging && (
              <DocInlineTags
                docData={data}
                searchEngine={searchEngine}
                suggestedTags={suggestedTags}
              />
            )}
          </CardContent>
        </Grid>
      </Grid>

      <Divider />

      <CardActions
        sx={{ py: 0.5 }}
        data-testid="RetrievalUnitCardDefault-Actions"
      >
        <Grid
          container
          justifyContent={'flex-end'}
          alignItems="center"
          spacing={0}
          columnSpacing={1}
        >
          <>
            {isPrivateDoc && data.ownerUuid !== me?.sub && (
              <Grid item container xs alignContent="center">
                <DocOwnerFromOrg ownerUuid={data.ownerUuid} />
              </Grid>
            )}

            {isPrivateDoc && data.ownerUuid === me?.sub && (
              <Grid item container xs alignContent="center">
                <DocOwner />
              </Grid>
            )}

            {renderFeedback && (
              <Grid item>
                <SendFeedback
                  withFeedbackMessage
                  searchId={searchId}
                  resultType={resultType}
                  resultId={docId}
                  initialValues={
                    feedback && {
                      feedbackText: feedback.text ?? '',
                      feedbackScore: feedback.score,
                    }
                  }
                />
              </Grid>
            )}

            {getSimilarDocsId && (
              <Grid item>
                <DocSimilarToButton
                  getSimilarDocsId={getSimilarDocsId}
                  eventName={TrackEventName.FindSimilarClicked}
                  eventProps={{ ontologyId }}
                />
              </Grid>
            )}

            <Grid item>
              <DocNotesToggle
                docId={organizeDocId}
                setNotesExpanded={setNotesExpanded}
                notesExpanded={notesExpanded}
                withSharedNotes={withSharedNotes}
              />
            </Grid>

            {withTagging && (
              <Grid item>
                <DocTags
                  docData={data}
                  searchEngine={searchEngine}
                  suggestedTags={suggestedTags}
                />
              </Grid>
            )}

            <Grid item>
              <DocFavoriteButton
                docData={data}
                searchId={searchId}
                resultType={resultType}
                searchEngine={searchEngine}
              />
            </Grid>

            {shareUri && (
              <Grid item>
                <DocShare
                  docId={docId}
                  docTitle={title}
                  docAuthors={authors}
                  searchId={searchId}
                  resultType={resultType}
                  shareUri={shareUri}
                  eventName={TrackEventName.ShareDocumentClicked}
                />
              </Grid>
            )}

            <Grid item>
              <DocActions
                data={data}
                moreActions={moreActions}
                onPrivateDocDelete={onPrivateDocDelete}
                onPrivateDocEdit={onPrivateDocEdit}
              />
            </Grid>
          </>
        </Grid>
      </CardActions>

      <Collapse
        unmountOnExit
        timeout="auto"
        in={notesExpanded}
        sx={{ bgcolor: 'grey.100' }}
      >
        <Divider />

        <ClickAwayListener onClickAway={handleNotesAccordionClickAway}>
          <CardContent>
            <Typography variant="h5">
              {t('retrievalUnit.notes.title')}
            </Typography>

            <Box my={2}>
              <DocNoteCreate
                docId={organizeDocId}
                docData={data}
                searchEngine={searchEngine}
              />
            </Box>

            <DocNotesResults
              docId={data.document.id}
              organizeDocId={organizeDocId}
            />
          </CardContent>
        </ClickAwayListener>
      </Collapse>
    </Card>
  );
}
