import { useQuery, UseQueryOptions } from 'react-query';
import { getDocumentAsset } from 'api/documentAssetApi/documentAssetApi';
import { DocAssetTypeEnum } from 'common/enums/DocAssetType.enum';
import { BaseError } from 'common/models/Error.interface';
import {
  DocExternalPublicAsset,
  DocPrivateAsset,
  DocPublicAsset,
} from '../RetrievalUnitData.interface';
import { useParsedHostname } from 'common/utils/useParsedHostname';
import { useSelector } from 'react-redux';
import { selectIndexCluster } from 'containers/User/user.slice';
import { useAuth } from 'containers/Auth/hooks/useAuth';
import { QUERY_OPTIONS } from 'common/constants/query-options';
import { userDocumentContentRetrieve } from 'api/privateDocsApi';

export const STALE_TIME = 1000 * 30; // 30 seconds

export const docPDFAssetQueryKeys = {
  all: ['docPDFAsset'] as const,
  details: (
    docGuid: string,
    assetType: string,
    assetValue: string,
    indexCluster?: string
  ) =>
    [
      ...docPDFAssetQueryKeys.all,
      docGuid,
      assetType,
      assetValue,
      indexCluster,
    ] as const,
};

export interface DocPDFData {
  content:
    | string
    | {
        data?: Uint8Array;
        url?: string;
        httpHeaders?: Map<string, string>;
        withCredentials?: boolean;
      };
  fileName: string;
}

export type UseDocPDFAssetProps = {
  docGuid: string;
  asset: DocPublicAsset | DocPrivateAsset | DocExternalPublicAsset;
  ownerUuid?: string;
  options?: Omit<
    UseQueryOptions<DocPDFData, BaseError>,
    'queryKey' | 'queryFn'
  >;
};

export const useDocPDFAsset = ({
  docGuid,
  asset: { assetType, assetValue },
  options,
  ownerUuid,
}: UseDocPDFAssetProps) => {
  const parsedHostname = useParsedHostname();
  const indexCluster = useSelector(selectIndexCluster);
  const { me } = useAuth();

  const query = useQuery<DocPDFData, BaseError>(
    docPDFAssetQueryKeys.details(
      docGuid,
      assetType,
      assetValue,
      indexCluster as string | undefined
    ),
    async () => {
      switch (assetType) {
        case DocAssetTypeEnum.PrivateAsset: {
          if (ownerUuid === me?.sub) {
            const { content, fileName } = await userDocumentContentRetrieve(
              assetValue,
              parsedHostname.tenant
            );
            return {
              content,
              fileName,
            };
          } else {
            const { data } = await getDocumentAsset({
              docGuid,
              assetType: 'pdf_url',
              tenant: parsedHostname.tenant,
              indexCluster: indexCluster as string | undefined,
            });
            return {
              content: data.downloadUrl,
              fileName: '',
            };
          }
        }
        case DocAssetTypeEnum.PublicAsset: {
          const { data } = await getDocumentAsset({
            docGuid,
            assetType: 'pdf_url',
            tenant: parsedHostname.tenant,
            indexCluster: indexCluster as string | undefined,
          });

          return {
            content: data.downloadUrl,
            fileName: '',
          };
        }
        default:
          return {
            content: assetValue,
            fileName: 'pdf_url',
          };
      }
    },
    {
      ...QUERY_OPTIONS,
      // Document becomes unaccessable after 30 seconds
      // so, no need to fetch the link again
      staleTime: STALE_TIME,
      ...options,
      enabled: options?.enabled && indexCluster !== null,
    }
  );

  return query;
};
