import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardProps,
  Chip,
  ClickAwayListener,
  Collapse,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { DocumentItem } from '@zarn/vendor/dist/saved-results';
import { HitType, SearchEngineEnum } from 'common/enums';
import { ResultType } from '@zarn/vendor/dist/query-logging';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';
import { AuthorsList } from 'common/components/AuthorsList/AuthorsList';
import DocNotesResults from 'containers/DocNotes/DocNotesResults';
import DocNoteCreate from 'containers/DocNotes/DocNoteCreate';
import DocTitle from 'common/components/Docs/DocTitle/DocTitle';
import DocMetadata from 'common/components/Docs/DocMetadata/DocMetadata';
import DocDate from 'common/components/Docs/DocDate/DocDate';
import { FeedbackData } from 'containers/Feedback/Feedback.interface';
import { TagDetailsBase } from 'api/tagsApi/tagsApi.types';
import { useStyles } from '../styles';
import { DocAction } from '../DocActions/DocActions.interface';
import { DocImage } from '../DocImage/DocImage';
import { RetrievalUnitExplanation } from '../RetrievalUnitExplanation/RetrievalUnitExplanation';
import DocStatus from '../DocStatus/DocStatus';
import DocSource from '../DocSource';
import DocTitleWithPDF from '../DocTitleWithPDF';
import DocTitleWithExternalPDF from '../DocTitleWithExternalPDF';
import DocAbstract from '../DocAbstract';
import { DocInlineTags } from '../DocInlineTags';
import DocActionsCompressed from '../DocActions/DocActionsCompressed';
import DocSourceWithIcon from '../DocSource/DocSourceWithIcon';
import { DocCardComposition } from 'containers/User/User.enum';

const DOCUMENT_TYPES: string[] = [
  HitType.Document,
  HitType.PrivateDocument,
  HitType.ExternalDocument,
];

export type RetrievalUnitCardCompressedProps = {
  data: RetrievalUnitData;
  withImage?: boolean;
  highlighted?: boolean;
  moreActions?: DocAction[];
  cardProps?: CardProps;
  explanation?: string;
  searchId?: string;
  resultType?: ResultType;
  withExplicitFeedback?: boolean;
  withSharedNotes?: boolean;
  withTagging?: boolean;
  suggestedTags?: TagDetailsBase[];
  searchEngine?: SearchEngineEnum;
  onDocumentAdd?: (addedDocuments: DocumentItem[]) => any;
  onPrivateDocDelete?: (id: string) => void;
  onPrivateDocEdit?: (data: RetrievalUnitData) => void;
  feedback?: FeedbackData;
  classes?: {
    root?: string;
  };
  evidenceNumber?: number;
  isCardBackground?: boolean;
  mapDocIndex?: number;
  pageNumber?: number;
  isEvidence?: boolean;
};

export default function RetrievalUnitCardCompressed({
  data,
  moreActions,
  cardProps,
  explanation,
  searchId,
  resultType,
  withExplicitFeedback,
  withSharedNotes,
  highlighted,
  suggestedTags,
  onPrivateDocDelete,
  onPrivateDocEdit,
  feedback,
  searchEngine,
  withImage = true,
  withTagging = true,
  classes: inputClasses,
  evidenceNumber,
  isCardBackground,
  mapDocIndex,
  pageNumber,
}: RetrievalUnitCardCompressedProps) {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const [notesExpanded, setNotesExpanded] = useState<boolean>(false);

  const {
    guid,
    document,
    source,
    title,
    abstractContent,
    representations,
    authors,
    organizeDocId,
    document: { id: docId },
    privateAsset,
    publicAsset,
    externalPublicAsset,
    status,
  } = data;

  const docAbstract =
    source === 'GitHub' ? representations.text : abstractContent;
  const isDocumentType = DOCUMENT_TYPES.includes(document.type);
  const hasPDF = privateAsset || publicAsset || externalPublicAsset;
  const renderFeedback = !!(withExplicitFeedback && searchId && resultType);

  const handleNotesAccordionClickAway = () => {
    if (notesExpanded) {
      setNotesExpanded(false);
    }
  };

  return (
    <Card
      {...cardProps}
      aria-label="retrieval unit"
      elevation={notesExpanded ? 10 : 1}
      className={clsx(
        isCardBackground === true || isCardBackground === undefined
          ? classes.cardCompressed
          : classes.cardCompressedNoBackground,
        highlighted && classes.highlighted,
        inputClasses?.root
      )}
      data-testid="RetrievalUnitCardCompressed"
    >
      <Grid container spacing={0} p={0} sx={{ pb: 1 }}>
        {withImage && (
          <DocImage
            data={data}
            cardComposition={DocCardComposition.Compressed}
          />
        )}
        <Grid item xs p={0}>
          <CardContent className={classes.cardCompressedContent}>
            {explanation && (
              <RetrievalUnitExplanation explanation={explanation} />
            )}

            {status && <DocStatus status={status} />}

            <div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'start',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  {evidenceNumber && (
                    <Typography component="span" pr={1}>
                      {evidenceNumber}.
                    </Typography>
                  )}
                  {mapDocIndex && pageNumber === 1 && (
                    <Typography component="span" pr={1}>
                      {mapDocIndex}.
                    </Typography>
                  )}
                  {isCardBackground === false ? (
                    <DocSourceWithIcon
                      uri={data.uri}
                      id={docId}
                      source={data.source}
                      searchId={searchId}
                      resultType={resultType}
                      duplicates={data.duplicates}
                      data={data}
                    />
                  ) : (
                    <DocSource
                      uri={data.uri}
                      id={docId}
                      source={data.source}
                      searchId={searchId}
                      resultType={resultType}
                      duplicates={data.duplicates}
                    />
                  )}

                  <>
                    {!hasPDF && (
                      <DocTitle
                        uri={data.uri}
                        title={title}
                        id={docId}
                        searchId={searchId}
                        resultType={resultType}
                      />
                    )}
                    {(publicAsset || privateAsset) && (
                      <DocTitleWithPDF
                        title={title}
                        id={docId}
                        searchId={searchId}
                        resultType={resultType}
                        docGuid={guid}
                        organizeDocId={organizeDocId}
                      />
                    )}
                    {externalPublicAsset && (
                      <DocTitleWithExternalPDF
                        title={title}
                        id={docId}
                        searchId={searchId}
                        resultType={resultType}
                        docData={data}
                        searchEngine={searchEngine}
                      />
                    )}
                  </>
                </div>
                <DocActionsCompressed
                  data={data}
                  moreActions={moreActions}
                  onPrivateDocDelete={onPrivateDocDelete}
                  onPrivateDocEdit={onPrivateDocEdit}
                  withTagging={withTagging}
                  suggestedTags={suggestedTags}
                  searchEngine={searchEngine}
                  searchId={searchId}
                  resultType={resultType}
                  setNotesExpanded={setNotesExpanded}
                  notesExpanded={notesExpanded}
                  withSharedNotes={withSharedNotes}
                  renderFeedback={renderFeedback}
                  feedback={feedback}
                />
              </div>
              {isCardBackground !== false ? (
                <DocMetadata cardStyle={{ margin: '0px' }}>
                  <DocDate date={data.date} year={data.year} />
                  {authors && <AuthorsList authors={authors} />}
                </DocMetadata>
              ) : undefined}

              {!isDocumentType && document.type && (
                <Chip size="small" label={document.type} />
              )}

              <DocAbstract
                highlight={data.highlight}
                abstractContent={docAbstract}
                id={docId}
                searchId={searchId}
                resultType={resultType}
                cardComposition={DocCardComposition.Compressed}
              />
            </div>

            {withTagging && (
              <DocInlineTags
                docData={data}
                searchEngine={searchEngine}
                suggestedTags={suggestedTags}
              />
            )}

            {isCardBackground === false ? (
              <DocDate date={data.date} year={data.year} smalltext={true} />
            ) : null}
          </CardContent>
        </Grid>
      </Grid>
      {isCardBackground === false ? <Divider /> : undefined}

      <Collapse
        unmountOnExit
        timeout="auto"
        in={notesExpanded}
        sx={{ bgcolor: 'grey.100' }}
      >
        <Divider />

        <ClickAwayListener onClickAway={handleNotesAccordionClickAway}>
          <CardContent>
            <Typography variant="h5">
              {t('retrievalUnit.notes.title')}
            </Typography>

            <Box my={2}>
              <DocNoteCreate
                docId={organizeDocId}
                docData={data}
                searchEngine={searchEngine}
              />
            </Box>

            <DocNotesResults
              docId={data.document.id}
              organizeDocId={organizeDocId}
            />
          </CardContent>
        </ClickAwayListener>
      </Collapse>
    </Card>
  );
}
