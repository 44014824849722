import React, { useCallback, useEffect } from 'react';
import {
  AppBar,
  Divider,
  Grid,
  Hidden,
  IconButton,
  Toolbar,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import PrintIcon from '@mui/icons-material/PrintOutlined';
import DownloadIcon from '@mui/icons-material/GetAppRounded';

import ZoomControl from './ZoomControl';
import PageControl from './PageControl';
import { HighlightMode, PDFViewerActionPayload } from '../../types';
import HighlightModeControl from './HighlightModeControl';
import PDFViewerMoreActions from './PDFViewerMoreActions';
import HighlightModePopover from './HighlightModePopover';
import {
  useDownloadUri,
  useFullscreen,
  useHighlightMode,
  useHighlightsVisibility,
  usePages,
  usePDFMetadata,
  useZoomLevel,
} from '../../hooks';
import PageControlMobile from './PageControlMobile';
import HighlightsVisibilityControl from './HighlightsVisibilityControl';
import FullscreenControl from './FullscreenControl/FullscreenControl';

export type PDFViewerToolbarProps = {
  onScrollToPage: (page: number) => void;
  onPDFViewerAction?: (payload: PDFViewerActionPayload) => Promise<void> | void;
};

const PDFViewerToolbar = ({
  onScrollToPage,
  onPDFViewerAction,
}: PDFViewerToolbarProps) => {
  const { downloadUri } = useDownloadUri();
  const {
    metadata: { fileName },
  } = usePDFMetadata();
  const { zoomLevel, setZoomLevel, maxZoomLevel, minZoomLevel } =
    useZoomLevel();
  const { highlightMode, setHighlightMode } = useHighlightMode();
  const { highlightsVisible, setHighlightsVisibility } =
    useHighlightsVisibility();
  const { numberOfPages, activePage, pages, setActivePage } = usePages();
  const { fullscreen, setFullscreen } = useFullscreen();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const keyPressHandler = () => {
      setFullscreen(!!document.fullscreenElement);
    };
    window.addEventListener('fullscreenchange', keyPressHandler);
    window.addEventListener('mozfullscreenchange', keyPressHandler);
    window.addEventListener('webkitfullscreenchange', keyPressHandler);
    window.addEventListener('MSFullscreenChange', keyPressHandler);

    return () => {
      window.removeEventListener('fullscreenchange', keyPressHandler);
      window.removeEventListener('mozfullscreenchange', keyPressHandler);
      window.removeEventListener('webkitfullscreenchange', keyPressHandler);
      window.removeEventListener('MSFullscreenChange', keyPressHandler);
    };
  }, [setFullscreen]);

  const handlePrint = () => {
    let iframe = document.createElement('iframe');
    iframe.style.visibility = 'hidden';
    iframe.src = downloadUri;
    document.body.appendChild(iframe);
    iframe.contentWindow?.focus();
    iframe.contentWindow?.print();

    if (onPDFViewerAction) {
      onPDFViewerAction({ action: 'print', data: { fileName } });
    }
  };

  const handleDownload = () => {
    if (onPDFViewerAction) {
      onPDFViewerAction({ action: 'download', data: { fileName } });
    }
  };

  const handleFullscreen = () => {
    setFullscreen(!fullscreen);
  };

  const handlePageChange = (page: number) => {
    setActivePage(page);

    onScrollToPage(page);
  };

  const handleHighlightModeChange = useCallback(
    (mode: HighlightMode) => {
      setHighlightMode(mode);

      if (mode !== 'none') {
        setHighlightsVisibility(true);
      }
    },
    [setHighlightMode, setHighlightsVisibility]
  );

  const handleHighlightsVisibilityChange = useCallback(
    (value: boolean) => {
      setHighlightsVisibility(value);

      setHighlightMode(value ? 'text' : 'none');
    },
    [setHighlightMode, setHighlightsVisibility]
  );

  return (
    <AppBar position="absolute" sx={{ bgcolor: 'background.paper' }}>
      <Toolbar sx={{ minHeight: 48, px: { xs: 1, sm: 2 } }}>
        <Grid container spacing={1}>
          <Grid
            item
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 0.5,
            }}
          >
            <Hidden smDown>
              <HighlightModeControl
                highlightMode={highlightMode}
                onHighlightModeChange={handleHighlightModeChange}
              />
            </Hidden>

            <Hidden smUp>
              <HighlightModePopover highlightMode={highlightMode}>
                <HighlightModeControl
                  highlightMode={highlightMode}
                  onHighlightModeChange={handleHighlightModeChange}
                />
              </HighlightModePopover>
            </Hidden>

            <Divider flexItem light orientation="vertical" />

            <HighlightsVisibilityControl
              highlightsVisible={highlightsVisible}
              onHighlightsVisibilityChange={handleHighlightsVisibilityChange}
            />
          </Grid>

          <Grid
            item
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 0.5,
              flex: 1,
              width: 'max-content',
            }}
          >
            <Hidden smDown>
              <PageControl
                activePage={activePage}
                numberOfPages={numberOfPages}
                onPageChange={handlePageChange}
              />
            </Hidden>

            <Hidden smUp>
              <PageControlMobile
                pages={pages}
                activePage={activePage}
                numberOfPages={numberOfPages}
                onPageChange={handlePageChange}
              />
            </Hidden>

            <ZoomControl
              zoom={zoomLevel}
              onZoom={setZoomLevel}
              maxZoom={maxZoomLevel}
              minZoom={minZoomLevel}
            />
          </Grid>

          <Grid
            item
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              gap: 0.5,
            }}
          >
            <Hidden smDown>
              <Tooltip title="Download">
                <IconButton
                  download={fileName}
                  component="a"
                  href={downloadUri}
                  rel="noopener noreferrer"
                  aria-label="Download"
                  onClick={handleDownload}
                  size="large"
                >
                  <DownloadIcon fontSize="small" />
                </IconButton>
              </Tooltip>

              <Tooltip title="Print">
                <IconButton
                  aria-label="Print"
                  onClick={handlePrint}
                  size="large"
                >
                  <PrintIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Hidden>

            <Hidden smUp>
              <PDFViewerMoreActions
                onPrint={handlePrint}
                onDownload={handleDownload}
              />
            </Hidden>
            {!isMobile && (
              <FullscreenControl
                fullscreen={!!fullscreen}
                handleFullscreen={handleFullscreen}
              />
            )}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default PDFViewerToolbar;
