import { RecommendationsSearchParams } from 'common/enums';
import { docGuidToDocId } from 'common/utils/documents';
import {
  GroupedRecommendations,
  RecommendationGroup,
} from './Recommendations.interface';
import { GetRecommendationsResultsArgs } from './recommendationsSlice.utils';
import { RecommendationResourceType } from './enums/RecommendationResourceType.enum';
import { RecommendedItem } from 'api/recommendationsApi';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';
import { TagDetails } from 'api/tagsApi/tagsApi.types';

export const getIdsByResourceType = (
  results: RecommendedItem[]
): {
  tagIds: number[];
  docIds: string[];
} => {
  return results.reduce(
    (acc, cur) => {
      const { resourceId, resourceType } = cur;

      if (resourceType === RecommendationResourceType.Tag) {
        return {
          ...acc,
          tagIds: [...acc.tagIds, Number(resourceId)],
        };
      }

      return {
        ...acc,
        docIds: [...acc.docIds, docGuidToDocId(resourceId)],
      };
    },
    { tagIds: [] as number[], docIds: [] as string[] }
  );
};

const getDate = (date: string): string => {
  return new Date(date).toLocaleDateString('en-US', {
    month: 'long',
    year: 'numeric',
    day: 'numeric',
  });
};

const groupByRecommendationId = (
  acc: RecommendationGroup,
  cur: RecommendedItem,
  docs: RetrievalUnitData[],
  tags: TagDetails[]
): RecommendationGroup => {
  const { recId: curId, resourceId } = cur;
  // Workaround: add `_0` to document id, as recommendations returns document ids
  // without `_0` suffix
  const documentHit = docs.find(
    (hit) => hit.document.id === docGuidToDocId(resourceId)
  );
  const tagItem = tags.find(
    (tagResponse) => tagResponse.id === Number(resourceId)
  );

  if (curId in acc && documentHit) {
    acc[curId].documents.push({
      explanation: cur.explanation,
      explanations: cur.explanations,
      docId: cur.resourceId,
      data: documentHit,
    });
  }

  if (curId in acc && tagItem) {
    acc[curId].tags.push({
      explanation: cur.explanation,
      explanations: cur.explanations,
      tagId: Number(cur.resourceId),
      data: tagItem,
    });
  }

  if (!(curId in acc)) {
    acc[curId] = {
      id: cur.recId,
      date: getDate(cur.dateCreated),
      totalRecCount: cur.recCount,
      documents: documentHit
        ? [
            {
              explanation: cur.explanation,
              explanations: cur.explanations,
              docId: cur.resourceId,
              data: documentHit,
            },
          ]
        : [],
      tags: tagItem
        ? [
            {
              explanation: cur.explanation,
              explanations: cur.explanations,
              tagId: Number(cur.resourceId),
              data: tagItem,
            },
          ]
        : [],
    };
  }

  return acc;
};

export const getGroupedRecommendationResults = (
  recommendations: RecommendedItem[],
  documents: RetrievalUnitData[],
  tags: TagDetails[]
): GroupedRecommendations[] => {
  const groups = recommendations.reduce(
    (acc, cur) => groupByRecommendationId(acc, cur, documents, tags),
    {} as RecommendationGroup
  );

  return Object.values(groups);
};

export const deserializeRecommendationsURL = (
  search: string,
  tenant: string,
  indexCluster?: string
): GetRecommendationsResultsArgs => {
  const searchParams = new URLSearchParams(search);
  const page = searchParams.get(RecommendationsSearchParams.Page) ?? 1;
  const recId =
    searchParams.get(RecommendationsSearchParams.RecUUID) ?? undefined;
  const docId =
    searchParams.get(RecommendationsSearchParams.DocGUID) ?? undefined;
  return {
    page: Number(page),
    recId,
    docId,
    tenant,
    indexCluster,
  };
};

export const recommendationsQueryKeys = {
  all: ['recommendations'] as const,
  lists: () => [...recommendationsQueryKeys.all, 'list'] as const,
  list: (props: {
    findRecInContext?: string;
    tagId?: number;
    indexCluster?: string | null;
  }) => [...recommendationsQueryKeys.lists(), props] as const,
};
