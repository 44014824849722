import { useChatAvailability } from 'common/hooks/useChatAvailability';

import { useLoggedInFeature } from 'containers/Auth/hooks/useLoggedInFeature';
import { useNavigationWithState } from 'common/hooks/useNavigationWithState';

interface Props {
  onOpenChatClick?: () => void;
  noteId: number;
}

export const useOpenQAChat = ({ onOpenChatClick, noteId }: Props) => {
  const navigation = useNavigationWithState();
  const { isChatAvailable } = useChatAvailability();

  const openChatNote = useLoggedInFeature<boolean>(() => {
    navigation.push(`/?tab=chat&noteId=${noteId}`);
  });

  const openQAChat = () => {
    onOpenChatClick?.();

    if (!isChatAvailable) {
      return;
    }

    openChatNote(true);
  };

  return { openQAChat };
};
