import { AxiosError } from 'axios';
import { APIError, BaseError } from 'common/models/Error.interface';

const DEFAULT_ERR_MESSAGE = 'Something went wrong';

export function deserializeAxiosError(err: any): BaseError {
  const error: AxiosError<APIError> = err;
  if (!error.response) {
    return { status: null, message: error.message || DEFAULT_ERR_MESSAGE };
  }

  const { status, data } = error.response;

  return {
    status,
    message: data.detail || data.message || DEFAULT_ERR_MESSAGE,
  };
}
