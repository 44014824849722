import React, { useCallback } from 'react';
import { ResultType } from '@zarn/vendor/dist/query-logging';
import { Chip, Popover, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { RetrievalUnitTweetsList } from './RetrievalUnitTweetsList';
import { TweetDocument } from '../types';
import { RetrievalUnitTweetsAvatarGroup } from './RetrievalUnitTweetsAvatarGroup';
import { useSendFeedback } from 'containers/Feedback/SendFeedback/hooks/useSendFeedback';
import { FeedbackType } from 'containers/Feedback/enums';
import CampaignIcon from '@mui/icons-material/Campaign';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    color: theme.palette.action.active,
  },
  title: {
    padding: theme.spacing(2, 2, 0),
  },
}));

type Props = {
  tweets: TweetDocument[];
  numberOfTweets?: number;
  searchId?: string;
  resultType?: ResultType;
  documentId: string;
};

export const RetrievalUnitTweets = ({
  tweets,
  numberOfTweets,
  searchId,
  resultType,
  documentId,
}: Props) => {
  const classes = useStyles();
  const { sendFeedback } = useSendFeedback();
  const [anchorEl, setAnchorEl] = React.useState<any | null>(null);
  const popoverOpen = Boolean(anchorEl);

  const handlePopoverOpen = (event: React.MouseEvent<any>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);

    if (searchId && resultType) {
      void sendFeedback({
        searchId,
        resultType,
        score: 0,
        resultId: documentId,
        feedbackType: FeedbackType.Tweets,
      });
    }
  };

  const handleTweetClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>, link: string) => {
      setAnchorEl(null);

      if (searchId && resultType) {
        void sendFeedback({
          searchId,
          resultType,
          score: 0,
          text: `Tweet: ${link}`,
          resultId: documentId,
          feedbackType: FeedbackType.TweetClick,
        });
      }
    },
    [searchId, resultType, sendFeedback, documentId]
  );

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  if (!numberOfTweets) return null;

  return (
    <div className={classes.container}>
      <CampaignIcon className={classes.icon} />

      {tweets.length > 0 ? (
        <>
          <RetrievalUnitTweetsAvatarGroup
            tweets={tweets}
            numberOfTweets={numberOfTweets}
            onClickMore={handlePopoverOpen}
            onTweetClick={handleTweetClick}
          />

          <Popover
            open={popoverOpen}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          >
            <RetrievalUnitTweetsList
              tweets={tweets}
              numberOfTweets={numberOfTweets}
              onTweetClick={handleTweetClick}
            />
          </Popover>
        </>
      ) : (
        <Chip size="small" label={numberOfTweets} />
      )}
    </div>
  );
};
