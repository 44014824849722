import { AxiosResponse } from 'axios';

import axiosInstance from 'api/axiosInstance';
import { Configuration, DocumentAssetsApi } from '@zarn/vendor/dist/search';
import { BASE_HEADERS, SERVICE_URL } from 'api/apiConfig';
import {
  DocumentAssetData,
  GetDocumentAssetPayload,
} from './documentAssetApi.types';

export const documentAssetsApi = new DocumentAssetsApi(
  new Configuration({ basePath: SERVICE_URL, baseOptions: BASE_HEADERS }),
  SERVICE_URL,
  axiosInstance
);

export const getDocumentAsset = async ({
  docGuid,
  assetType,
  indexCluster,
  tenant,
}: GetDocumentAssetPayload): Promise<AxiosResponse<DocumentAssetData>> => {
  const { data, ...rest } = await documentAssetsApi.retrieve({
    requesterUuid: '',
    guid: docGuid,
    assetType,
    indexCluster,
    tenant,
  });

  return {
    ...rest,
    data: {
      downloadUrl: data.download_url,
      fileSize: data.file_size,
    },
  };
};
