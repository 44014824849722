import { PrivateDocAccessRoleEnum } from 'common/enums';
import { DocAssetTypeEnum } from 'common/enums/DocAssetType.enum';
import {
  DocPrivateAsset,
  RetrievalUnitAuthor,
  RetrievalUnitData,
} from 'containers/RetrievalUnit/RetrievalUnitData.interface';
import {
  UserDocumentForm,
  UserDocumentItem,
} from '@zarn/vendor/dist/user-documents';
import { PrivateDocFormValues } from './PrivateDocForm/PrivateDocForm.interface';
import { DocSourcesEnum } from 'common/utils/documents';
import { TagType } from '@zarn/vendor/dist/saved-results';

export const mapPrivateDocAuthors = (
  authors: string[]
): RetrievalUnitAuthor[] => {
  return authors
    .map((a) => ({ fullName: a.trim() }))
    .filter(({ fullName }) => fullName);
};

export const sanitizePrivateDocUri = (uri: string | null): string | null => {
  if (!uri || !uri.startsWith('https://')) return null;

  return uri;
};

export const mapPrivateDocUri = (
  uri: string | null,
  privateAssetId: string | null = null
): string | null => {
  if (!uri && privateAssetId) {
    return `${window.env.ORIGIN_URL}/files/${privateAssetId}`;
  }

  return sanitizePrivateDocUri(uri);
};

export const mapPrivateDocAsset = (
  privateAssetId: string | null = null
): DocPrivateAsset | null => {
  return privateAssetId
    ? {
        assetType: DocAssetTypeEnum.PrivateAsset,
        assetValue: privateAssetId,
      }
    : null;
};

export const serializePrivateDocFormAuthors = (authors: string): string[] => {
  const re = /\s*(?:&|,| and |$)\s*/;
  return authors.split(re);
};

export const deserializeUserDocumentItem = (
  data: UserDocumentItem
): RetrievalUnitData => {
  const {
    id,
    uri,
    crawled_document_id: guid,
    document_metadata: metadata,
    uri_hash: organize_doc_id,
  } = data;
  const privateAsset = mapPrivateDocAsset(id);

  return {
    guid,
    organizeDocId: organize_doc_id,
    uri: mapPrivateDocUri(
      uri || null,
      privateAsset?.assetValue ? privateAsset?.assetValue : null
    ),
    date: String(metadata.year),
    year: metadata.year || null,
    ontologyId: null,
    title: metadata.title ?? '',
    numberOfCitations: 0,
    numberOfRefs: 0,
    abstractContent: metadata.description ?? '',
    authors: mapPrivateDocAuthors(metadata.authors || []),
    source: (metadata.source as DocSourcesEnum) ?? DocSourcesEnum.Default,
    duplicates: [],
    tweets: [],
    githubRepos: [],
    ...(privateAsset ? { privateAsset } : {}),
    representations: { text: '' },
    document: {
      id,
      type: 'private_document',
    },
  };
};

export const serializeUserDocumentFormValues = ({
  uri,
  title,
  abstractText,
  year,
  source,
  authors,
  shareWithOrg,
  base64Content,
  fileName,
}: PrivateDocFormValues): UserDocumentForm => {
  const normalizedTitle = title.trim() || undefined;
  const normalizedYear = year || undefined;

  return {
    access_roles: shareWithOrg
      ? [PrivateDocAccessRoleEnum.Own, PrivateDocAccessRoleEnum.Org]
      : [PrivateDocAccessRoleEnum.Own],
    base64_content: base64Content,
    content_file_name: fileName,
    uri: uri || undefined,
    document_metadata: {
      title: normalizedTitle,
      year: normalizedYear,
      source: source ?? '',
      authors: serializePrivateDocFormAuthors(authors),
      description: abstractText,
    },
  };
};

export const isSharedTag = (tag: { type: TagType }): boolean =>
  tag.type === TagType.Shared;
